/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  changeStep,
  closeIdologyOrGiactFailureDialog,
} from '../../actions/userExperienceActions';
import { setBusinessDescriptions } from '../../actions/businessDescriptionActions';
import { getBusinessFormOptions } from '../../actions/businessFormActions';
import { getUserProfile, logoutUser } from '../../actions/authActions';
import { executeBusinessSignUpTaskRequest } from '../../actions/businessSignUpActions';
import { executeBusinessSignInTaskRequest } from '../../actions/businessSignInActions';
import { postThreatMetrixSession } from '../../actions/threatMetrixActions';
import {
  postGiactImage,
  processBusinessGIACTValidation,
  showGiactErrorDialog
} from '../../actions/giactActions';

import BusinessSignUpForm from '../Forms/BusinessForm';
import OrderSummary from '../Payment/OrderSummary';
import Footer from '../Shared/Footer';
import Loading from '../Shared/Loading/Loading';
import AttachFileDialog from '../Shared/Dialog/AttachFileDialog';

import routes from '../../constants/routes';
import constants from '../../constants/misc';
import actionTypes from '../../constants/actionTypes';
import States from '../../constants/states';
import FileUtil from '../../util/FileUtil';
import CommonUtil from '../../util/CommonUtil';
import {
  toggleShowInvalidRoutingDialog
} from '../../actions/twoFactorActions';
import { getVerificationToken, saveVerificationData } from '../../actions/verificationActions';
import textUtil from '../../util/FormatTextUtil';
import BackdropLoader from '../Shared/BackdropLoader';
import InvalidRoutingDialog from '../Shared/Dialog/InvalidRoutingNumberDialog';
import { compose } from 'redux';
import WithNavigation from '../../hoc/withNavigation';
import formIds from '../../constants/formIds';

class BusinessFormContainer extends Component {

  state = {
    businessFormValues: null
  };

  componentDidMount () {
    if (!this.props.auth.isAuthenticated) {
      return this.logoutAndRedirect();
    }

    if (this.props.user.is_ecp && this.props.auth.twoFactorAuth || !this.props.user.is_ecp) {
      return this.redirectToBusinessForm();
    }

    this.logoutAndRedirect();
  }

  redirectToBusinessForm = () => {
    this.props.changeStep('businessForm', this.props.userExperience.steps);
    this.props.getBusinessFormOptions();
    this.props.user.data?.user_id && this.props.user.selectedMerchantAccount && this.props.getUserProfile(this.props.user);
  }

  getBusinessFormValues = () => {
    return this.state.businessFormValues
  }

  logoutAndRedirect = async () => {
    await this.props.logoutUser();
    await this.props.changeLocation(routes.home);
  };

  giactValidation = (values) => {
    const data = values;
    const name = this.props.accountProfile.data?.principal;

    if (name && this.props.signInPath) {
      data.first_name = name.substr(0, name.indexOf(' '));
      data.last_name = name.substr(name.indexOf(' ') + 1);
    } else {
      data.first_name = this.props.clientSignUpValues.dialogData?.first_name || data.customer_first_name;
      data.last_name = this.props.clientSignUpValues.dialogData?.last_name || data.customer_last_name;
    }

    this.props.processBusinessGIACTValidation(data).then((res) => {
      if (res.response?.data?.response && !res.response?.data?.response.isValidRoutingNumber) {
        this.props.showInvalidRoutingDialog(true);
      } else if (res.type === actionTypes.processGIACTValidationSuccess && res.response.data.passFail === constants.giactSuccess) {
        this.submitBusiness(values);
      } else {
        this.props.showGiactErrorDialog();
      }
    }).catch((err) => {
      this.props.showGiactErrorDialog();
    });
  };

  onSubmitBusinessClick = () => {
    this.props.submitForm();
  };

  submitBusiness = async (values) => {
    const {
      user,
      signInPath,
      accountProfile,
      closeIdologyOrGiactFailureDialog,
      executeBusinessSignInTaskRequest,
      executeBusinessSignUpTaskRequest,
    } = this.props;

    closeIdologyOrGiactFailureDialog();

    const valuesToOmit = ['sec_street_address', 'sec_city', 'sec_state', 'sec_zip_code', 'first_name', 'last_name'];

    const businessValues = _.omit(values, valuesToOmit);
    if (signInPath) {
      await executeBusinessSignInTaskRequest({
        formValues: values,
        clientData: accountProfile.data,
        clientEmail: user.data.username,
        clientTitle: values.customer_title,
        clientDOB: values.customer_date_of_birth,
        visitorId: values.visitor_id
      });
    } else {
      await executeBusinessSignUpTaskRequest(businessValues);
    }

  };

  handleAddFiles = (values) => {
    const requiredFields = _.pick(this.getBusinessFormValues(), ['bank_routing_number', 'account_number', 'account_type']);
    requiredFields.giactAccountResponseCode = this.props.fraudVerification.giactAccountResponseCode;
    requiredFields.image = '';
    const image = values?.files?.[0];
    if (!image) {
      this.setState({ openUploadError: true });
    } else {
      return FileUtil.getBase64(image.file)
        .then((result) => {
          requiredFields.image = result.base64;
          this.props.postGiactImage(requiredFields).then(result => {
            this.submitBusiness(this.getBusinessFormValues());
          });
        })
        .catch(() => {
          this.setState({ openUploadError: true });
        });
    }
  };

  closeGiactFailureDialog = () => {
    this.props.closeIdologyOrGiactFailureDialog();
  };

  goBack = () => {
    const { userExperience, signInPath, changeStep } = this.props;
    const step = signInPath ? 'shoppingBag' : 'clientForm';
    const route = signInPath ? routes.home : routes.clientSignUp;
    changeStep(step, userExperience.steps);
    this.props.changeLocation(route);
  };

  submitButtonAction = (values) => {
    const { signInPath } = this.props;

    this.setState({ businessFormValues: values });

    if (signInPath) {
      this.verificationActions(values);
    }

    this.giactValidation(values);
  };

  verificationActions = (formValues) => {
    const verificationValues = {
      name: formValues.customer_first_name,
      lastName: formValues.customer_last_name,
      phoneNumber: textUtil.formatPhoneNumber2FA(formValues.business_telephone),
      twoFactorAuthenticated: true
    };
    this.props.getVerificationToken(formValues.customer_ssn);
    this.props.saveVerificationData(verificationValues);
  };


  render () {
    const {
      userExperience,
      businessOptions,
      businessDescription,
      accountProfile,
      titleOptions,
      businessSignUp,
      fraudVerification
    } = this.props;
    const isFetching = businessDescription.isFetching || businessOptions.isFetching || accountProfile.isFetching;
    const buttonDisabled = isFetching || businessSignUp.isValidatingGiact || businessSignUp.isFetching || userExperience.isFetching;

    const giactFailsImageDialog = this.props.fraudVerification.showGiactFailureDialog &&
      <AttachFileDialog
        handleClose={this.props.closeIdologyOrGiactFailureDialog}
        handleAddFiles={this.handleAddFiles}
        backRoute={'home'}
        {...this.props}
      />;

    const invalidRoutingNumberDialog = fraudVerification.showInvalidRoutingDialog &&
      <InvalidRoutingDialog
        handleClose={() => this.props.showInvalidRoutingDialog(!fraudVerification.showInvalidRoutingDialog)}
        {...this.props}
      />;

    if (isFetching) {
      return (<Loading/>);
    }
    return (
      <>
        <BackdropLoader show={businessSignUp.isValidatingGiact}/>
        <div className='businessForm'>
          <div className='introText'>
            <h1>{'Complete your business profile.'}</h1>
            <p>&nbsp;</p>
          </div>
          {businessOptions.data && businessDescription.data &&
          <div className='businessWrapper'>
            <BusinessSignUpForm
              {...this.props}
              onSubmit={this.submitButtonAction}
              businessTypeOptions={businessOptions.data.industries}
              businessOptions={businessOptions.data.business}
              businessDescriptionOptions={businessDescription.data}
              averageTicketAmountOptions={businessOptions.data.ticketAmount}
              averageMonthlyVolumeOptions={businessOptions.data.monthlyVolume}
              statesOptions={States}
              legalBusinessStructureOptions={businessOptions.data.legalBusinessStructure}
              titleOptions={titleOptions}
              isDisabled={buttonDisabled}
            />
            <div className='orderWrapper'>
              <OrderSummary/>
            </div>
          </div>
          }
        </div>
        <Footer
          backAction={this.goBack}
          buttonAction={this.onSubmitBusinessClick}
          buttonLabel={buttonDisabled ? 'Processing' : 'Continue'}
          buttonDisabled={buttonDisabled}
        />
        {giactFailsImageDialog}
        {invalidRoutingNumberDialog}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const signInPath = !!(state.user?.data?.user_id);
  return {
    userExperience: state.userExperience,
    businessOptions: state.businessFormOptions,
    businessDescription: state.businessDescription,
    accountProfile: state.accountProfile,
    user: state.user,
    signInPath,
    titleOptions: state.clientSignUp.clientTitles,
    fraudVerification: state.fraudVerification,
    businessSignUp: state.businessSignUp,
    clientSignUpValues: state.clientSignUp,
    auth: state.auth,
    application: state.application,
    formValues: state.form
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return ({
    changeStep: (step, steps) => dispatch(changeStep(step, steps)),
    executeBusinessSignInTaskRequest: (data) => dispatch(executeBusinessSignInTaskRequest(data, ownProps)),
    setBusinessDescriptions: (data) => {
      dispatch(setBusinessDescriptions(data));
    },
    getBusinessFormOptions: () => {
      dispatch(getBusinessFormOptions());
    },
    getUserProfile: (user) => dispatch(getUserProfile(user)),
    executeBusinessSignUpTaskRequest: async data => {
      await dispatch(executeBusinessSignUpTaskRequest(data));
      await ownProps.navigate(routes.terms);
      await dispatch(postThreatMetrixSession(ownProps.application.threatMetrixSession));
    },
    processBusinessGIACTValidation: data => dispatch(processBusinessGIACTValidation(data)),
    postGiactImage: (requiredFields) => dispatch(postGiactImage(requiredFields)),
    closeIdologyOrGiactFailureDialog: () => dispatch(closeIdologyOrGiactFailureDialog()),
    logoutUser: () => dispatch(logoutUser()),
    showGiactErrorDialog: () => dispatch(showGiactErrorDialog()),
    submitForm: () => CommonUtil.submitFormById(formIds.businessSignUpForm),
    changeLocation: route => ownProps.navigate(route),
    getVerificationToken: (ssn) => dispatch(getVerificationToken(ssn)),
    saveVerificationData: (value) => dispatch(saveVerificationData(value)),
    closeTwoFactorErrorDialog: () => dispatch(closeTwoFactorErrorDialog()),
    showInvalidRoutingDialog: (show) => dispatch(toggleShowInvalidRoutingDialog(show)),
  });
};

export default compose(
  WithNavigation,
  connect(mapStateToProps, mapDispatchToProps)
)(BusinessFormContainer);
