/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import actionTypes from '../constants/actionTypes';
import dayjs from '../config/DayjsConfig';
import { CALL_API, helperGetCSRFCookieToken } from '../middleware/api';
import FingerPrintUtil from '../util/FingerPrintUtil';
import jwtDecode from 'jwt-decode';

export function requestSignup() {
  return {
    type: actionTypes.signupRequest,
    isFetching: true,
    isAuthenticated: false
  };
}

export function requestLogin() {
  return {
    type: actionTypes.loginRequest,
    isFetching: true,
    isAuthenticated: false
  };
}

export function requestAccount() {
  return {
    type: actionTypes.accountRequest,
    isFetching: true,
    isAuthenticated: false
  };
}

export function requestLogout() {
  return {
    type: actionTypes.logoutRequest,
    isFetching: true,
    isAuthenticated: true
  };
}

export function receiveLogout() {

  return {
    type: actionTypes.logoutSuccess,
    isFetching: false,
    isAuthenticated: false
  };
}

export function receiveSignup(account) {

  return {
    type: actionTypes.signupSuccess,
    isFetching: false,
    isAuthenticated: true,
    token: account.token
  };
}

export function signUpValues(userValues) {

  localStorage.setItem('pa-apply-signup', JSON.stringify(userValues));

  return {
    type: actionTypes.signUpValues,
    userValues: userValues
  };
}

export function receiveLogin(account) {

  return {
    type: actionTypes.loginSuccess,
    isFetching: false,
    isAuthenticated: true,
    token: account.token
  };
}

export function signupError(message) {
  return {
    type: actionTypes.signupFailure,
    isFetching: false,
    isAuthenticated: false,
    message
  };
}

export function loginError(message) {
  return {
    type: actionTypes.loginFailure,
    isFetching: false,
    isAuthenticated: false,
    message
  };
}

export function closeLoginErrorDialog() {
  return {
    type: actionTypes.closeLoginErrorDialog,
  };
}

export function receiveAccount(account) {
  const merchant = account?.user?.merchantAccounts?.[0];
  if (merchant) {
    const selectedMerchantAccount = merchant.mea_id;
    const selectedMerchantAccountRole = merchant.role;
    const selectedMerchantAcceptedTermsAndConditions = merchant.accepted_terms_conditions ? merchant.accepted_terms_conditions : false;
    const selectedMerchantTCID = merchant.tc_id;
    const selectedMerchantTCEnabled = merchant.tc_enabled;
    localStorage.setItem('pa-apply-ma', selectedMerchantAccount);

    return {
      type: actionTypes.accountSuccess,
      isFetching: false,
      isAuthenticated: true,
      user: account.user,
      selectedMerchantAccount: selectedMerchantAccount,
      role: selectedMerchantAccountRole,
      acceptedTermsAndConditions: selectedMerchantAcceptedTermsAndConditions,
      termsAndConditionsID: selectedMerchantTCID,
      termsAndConditionsEnabled: selectedMerchantTCEnabled
    };
  } else {
    return {
      type: actionTypes.accountSuccess,
      isFetching: false,
      isAuthenticated: true,
      user: account.user,
    };
  }
}

export function receiveProfile(response) {
  return {
    type: actionTypes.accountProfileSuccess,
    isFetching: false,
    isAuthenticated: true,
    response
  };
}

export function accountError(message) {
  return {
    type: actionTypes.accountFailure,
    isFetching: false,
    isAuthenticated: false,
    message
  };
}

export function getUserProfile(user) {

  const endpoint = `users/accounts/${user.selectedMerchantAccount}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.accountProfileRequest, actionTypes.accountProfileSuccess, actionTypes.accountProfileFailure]
    }
  };

}

export function loginUser(credentials) {

  const config = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'requestDate': dayjs().format('ddd, DD MMM YYYY HH:mm:ss Z'),
      'csrf-token': helperGetCSRFCookieToken()
    },
    body: `username=${encodeURIComponent(credentials.username)}&password=${encodeURIComponent(btoa(credentials.password))}`
  };

  return (dispatch, getState) => {

    dispatch(requestLogin());
    dispatch(requestAccount());

    const url = `${serverBaseUrl}users/create-session`;
    config.headers = FingerPrintUtil.addFingerPrintHeaders(getState(), config.headers);

    return fetch(url, config)
      .then(response =>
        response.json()
          .then(payload => ({payload, response}))
      ).then(({payload, response}) => {
        if (!response.ok) {
          dispatch(loginError(payload.message));
          dispatch(accountError(payload.message));
          return Promise.reject(payload);
        } else {
          localStorage.setItem('pa-apply-u', JSON.stringify(payload.user));

          if (!payload.user.is_ecp) {
            localStorage.setItem('pa-apply-token', payload.token);
          }

          dispatch(receiveLogin(payload));
          dispatch(receiveAccount(payload));
          return Promise.resolve(payload);
        }
      }).catch(error => Promise.reject(error));
  };
}

export function expireToken() {

  const endpoint = 'users/expireToken';

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.expireTokenRequest, actionTypes.expireTokenSuccess, actionTypes.expireTokenFailure],
    },
    type: actionTypes.expireTokenRequest
  };

}

export function logoutUser() {

  return dispatch => {
    dispatch(requestLogout());
    dispatch(expireToken());
    localStorage.removeItem('pa-apply-token');
    localStorage.removeItem('pa-apply-u');
    localStorage.removeItem('pa-apply-ma');
    localStorage.removeItem('pa-apply-signup');
    localStorage.removeItem('pa-shopping-bag');
    localStorage.removeItem('pa-giactValidation');
    localStorage.removeItem('pa-apply-application-id');
    localStorage.removeItem('pa-apply-session-id');
    localStorage.removeItem('pa-apply-phone-authenticated');
    dispatch(receiveLogout());
  };

}

export function signUpUser(userValues) {

  const config = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'requestDate': dayjs().format('ddd, DD MMM YYYY HH:mm:ss Z'),
      'csrf-token': helperGetCSRFCookieToken()
    },
    body: `email=${encodeURIComponent(userValues.email)}&first_name=${encodeURIComponent(userValues.first_name)}&last_name=${encodeURIComponent(userValues.last_name)}&phone=${encodeURIComponent(userValues.phone)}`
  };

  return (dispatch, getState) => {

    dispatch(requestSignup());
    dispatch(requestAccount());

    const url = serverBaseUrl + 'users/create-signup-session';
    config.headers = FingerPrintUtil.addFingerPrintHeaders(getState(), config.headers);

    return fetch(url, config)
      .then(response =>
        response.json()
          .then(payload => ({payload, response}))
      ).then(({payload, response}) => {
        if (!response.ok) {
          dispatch(signupError(payload.message));
          dispatch(accountError(payload.message));
          return Promise.reject(payload);
        } else {
          localStorage.setItem('pa-apply-token', payload.token);
          localStorage.setItem('pa-apply-u', JSON.stringify(payload.user));
          dispatch(receiveSignup(payload));
          dispatch(receiveAccount(payload));
          dispatch(signUpValues(userValues));
        }
      }).catch(error => console.log('Error: ', error));
  };
}

export function refreshToken() {

  const endpoint = 'users/refresh';

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.refreshTokenRequest, actionTypes.refreshTokenSuccess, actionTypes.refreshTokenFailure]
    }
  };
}

export function checkTokenExpiration() {
  return (dispatch, getState) => {
    const token = localStorage.getItem('pa-apply-token');

    if (token && token !== 'null') {

      const tokenExpiration = jwtDecode(token).exp;
      const timeUntilNeedNewToken = (3 * 60);
      const dateNow = Date.now() / 1000;
      const expiresMinusRefreshTime = tokenExpiration - timeUntilNeedNewToken;
      const tokenExpired = tokenExpiration && (expiresMinusRefreshTime < dateNow);

      const {tokenAutoRenew} = getState().auth;

      if (tokenExpired && tokenAutoRenew) {

        dispatch(refreshToken()).then(() => {
          const {auth} = getState();
          /*istanbul ignore else*/
          if (auth.newToken) {
            localStorage.setItem('pa-apply-token', auth.newToken);
          }
        });

      } else {
        const tokenActionType = tokenExpired ? actionTypes.tokenExpiring : actionTypes.tokenValid;
        dispatch(handleTokenAction(tokenActionType));
      }
    }
  };
}

export function toggleRefreshTokenDialog(value) {
  return {
    type: actionTypes.toggleRefreshTokenDialog,
    value
  };
}

export function handleTokenAction(tokenActionType) {
  return {
    type: tokenActionType
  };
}

export function checkUsername(username) {
  const endpoint = `utils/checkUsername/${username}`;
  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      encrypted: true,
      types: [actionTypes.checkUsernameRequest, actionTypes.checkUsernameSuccess, actionTypes.checkUsernameFailure]
    }
  };
}
